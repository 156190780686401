.file-uploader-wrapper {
  lr-file-uploader-inline,
  lr-file-uploader-minimal,
  lr-file-uploader-regular {
    @apply font-figtree #{!important};

    lr-progress-bar-common {
      @apply hidden #{!important};
    }
  }

  lr-file-uploader-regular {
    lr-simple-btn lr-drop-area > button,
    .primary-btn,
    .secondary-btn {
      @apply select-none whitespace-nowrap text-small font-normal subpixel-antialiased outline-none transition-transform-colors-opacity hover:opacity-hover active:scale-[0.97] #{!important};
      &:disabled {
        @apply pointer-events-none opacity-disabled #{!important};
      }
    }

    lr-simple-btn lr-drop-area > button,
    .primary-btn {
      @apply bg-primary text-primary-foreground #{!important};
    }

    .secondary-btn {
      @apply bg-default/20 text-default-foreground #{!important};
    }

    lr-modal {
      lr-upload-list {
        button.remove-btn.mini-btn {
          @apply text-danger-500 hover:bg-danger/20 #{!important};
        }

        lr-file-item {
          .file-name-wrapper {
            .file-name {
              @apply dark:text-default-800 #{!important};
            }
            .file-error {
              @apply text-xs text-danger-400 #{!important};
            }
          }
        }
      }

      dialog {
        &::backdrop {
          @apply bg-overlay/20 #{!important};
        }

        lr-upload-list {
          .common-error {
            @apply rounded bg-red-500/10 text-red-500 dark:bg-red-500/5 dark:text-red-400 #{!important};
          }
        }
      }
    }
  }

  lr-file-uploader-minimal {
    lr-drop-area {
      @apply text-primary #{!important};
    }

    lr-file-item {
      button.remove-btn {
        @apply text-danger-500 hover:bg-danger/20 #{!important};
      }

      .file-name-wrapper {
        .file-name {
          @apply dark:text-default-800 #{!important};
        }
        .file-error {
          @apply text-xs text-danger-400 #{!important};
        }
      }
    }
  }

  lr-file-uploader-inline {
    lr-start-from .content,
    lr-upload-list {
      @apply bg-transparent py-0 #{!important};
    }

    .primary-btn,
    .secondary-btn {
      @apply select-none whitespace-nowrap text-small font-normal subpixel-antialiased outline-none transition-transform-colors-opacity hover:opacity-hover active:scale-[0.97] #{!important};
      &:disabled {
        @apply pointer-events-none opacity-disabled #{!important};
      }
    }

    .primary-btn {
      @apply bg-primary text-primary-foreground #{!important};
    }

    .secondary-btn {
      @apply bg-default/20 text-default-foreground #{!important};
    }

    button.remove-btn.mini-btn {
      @apply text-danger-500 hover:bg-danger/20 #{!important};
    }

    lr-start-from {
      .content {
        .content-wrapper {
          .text {
            @apply text-small my-0 #{!important};
          }
          .helper-text-wrapper {
            @apply flex select-none justify-center gap-1 text-tiny text-default-500 mt-0.5 mb-2.5 #{!important};
          }
        }
      }

      lr-drop-area {
        @apply text-primary  #{!important};
      }
    }

    lr-upload-list {
      lr-activity-header {
        @apply pt-0 text-default-500 #{!important};
      }

      lr-file-item {
        .file-name-wrapper {
          .file-name {
            @apply dark:text-default-800 #{!important};
          }
          .file-error {
            @apply text-xs text-danger-400 #{!important};
          }
        }
      }

      .common-error {
        @apply rounded bg-red-500/10 text-red-500 dark:bg-red-500/5 dark:text-red-400 #{!important};
      }
    }

    lr-url-source {
      .content {
        @apply gap-4 #{!important};
      }
    }

    lr-external-source {
      @apply w-full md:w-[896px] rounded-lg bg-transparent #{!important};

      .content {
        .iframe-wrapper {
          @apply rounded-lg min-h-96 border-tiny border-default-300 dark:border-default-100 #{!important};
        }

        .toolbar {
          @apply border-none #{!important};
        }
      }
    }
  }

  &.uploader-hide-native-toolbar {
    lr-upload-list {
      .toolbar {
        @apply hidden #{!important};
      }

      lr-file-item {
        .file-actions {
          button.remove-btn {
            @apply hidden #{!important};
          }
        }
      }
    }
  }
}

@layer base {
  :root {
    .file-uploader-wrapper {
      lr-file-uploader-inline,
      lr-file-uploader-minimal,
      lr-file-uploader-regular {
        --darkmode: 0 !important;
        --clr-accent: var(--color-indigo-500) !important;
        --clr-accent-light: var(--color-indigo-300_20) !important;
        --clr-accent-lightest: var(--color-indigo-300_10) !important;
      }
    }
  }
  :root[class~='dark'] {
    .file-uploader-wrapper {
      lr-file-uploader-inline,
      lr-file-uploader-minimal,
      lr-file-uploader-regular {
        --darkmode: 1 !important;
        --clr-accent: var(--color-indigo-500) !important;
        --clr-accent-light: var(--color-indigo-400_30) !important;
        --clr-accent-lightest: var(--color-indigo-400_10) !important;
      }
    }
  }
}
