.recharts-pie-sector {
  path {
    outline: none;
  }
}

.recharts-layer {
  &.recharts-pie {
    outline: none;
  }
}
